import _regex from "./properties/Any/regex";
import _regex2 from "./categories/Cc/regex";
import _regex3 from "./categories/Cf/regex";
import _regex4 from "./categories/P/regex";
import _regex5 from "./categories/Z/regex";
var exports = {};
exports.Any = _regex;
exports.Cc = _regex2;
exports.Cf = _regex3;
exports.P = _regex4;
exports.Z = _regex5;
export default exports;
export const Any = exports.Any,
      Cc = exports.Cc,
      Cf = exports.Cf,
      P = exports.P,
      Z = exports.Z;